<template>
  <div class="user-conf-history">
    <h2 class="user-conf-history__title">{{ $t('confirmation-history-1') }}</h2>
    <div class="user-conf-history__body body">
      <div class="body__header">
        <div class="body__header-date text" style="max-width: 120px">{{ $t('date') }}</div>
        <div class="body__header-time text" style="max-width: 78px">{{ $t('time') }}</div>
        <div class="body__header-user text" style="max-width: 180px">{{ $t('status') }}</div>
        <div class="body__header-event text" style="max-width: 100%">{{ $t('notification-description') }}</div>
      </div>
      <div class="body__row" v-for="(item, key) in getConfHistory" :key="key">
        <div class="body__row-date text" style="max-width: 120px">{{ getFullDate(item.createdAt).slice(0, 10) || null }}</div>
        <div class="body__row-time text" style="max-width: 78px">{{ getFullDate(item.createdAt).slice(12, 17) || null }}</div>
        <div class="body__row-user text" style="max-width: 180px">{{ getStatus(item.type) }}</div>
        <div class="body__row-event text" style="max-width: 100%">{{ item.bodyText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {getFullDate} from "../../utils/general";
export default {
  name: "UserConfHistory",
  methods: {
    getFullDate,
    ...mapActions(['userConfHistory']),
    getStatus (status) {
      if (status === 'UserRoleAccepted') {
        return this.$t('confirmed')
      } else if (status === 'UserRoleRejected') {
        return this.$t('rejected-1')
      } else if(status === 'UserRoleRequest') {
        return this.$t('request-2')
      } else {
        return this.$t('rejected-1')
      }
    }
  },
  mounted () {
    this.userConfHistory(this.$route.params.id)
  },
  computed: {
    ...mapGetters(['getConfHistory']),
  }
}
</script>

<style lang="scss" scoped>
.user-conf-history {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  background: #FFFFFF;
  border-radius: 20px;
  height: max-content;
  padding-bottom: 50px;
  overflow-y: auto;

  @media (max-width: 996px) {
    margin-left: 0;
    width: 100%;
  }

  &__title {
    margin: 20px 0 20px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1B1A1F;
  }
  .body {
    display: flex;
    min-width: 800px;
    flex-direction: column;

    &__header {
      display: flex;
      background: #E21F1F;

      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 103%;
        color: #FFFFFF;
        width: 100%;
        padding: 10px 0 10px 20px;

        @media (max-width: 768px) {
          padding: 10px 0 10px 10px;
        }
      }
    }

    &__row {
      display: flex;

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #1B1A1F;
        width: 100%;
        padding: 10px 0 10px 20px;
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: #F5F5F5;

        @media (max-width: 768px) {
          padding: 10px 0 10px 10px;
        }
      }
    }
  }
  &__action {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px;
    cursor: pointer;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #CE2121;
    }
  }
}
</style>